"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCardInputBlockAssignee = exports.MultiSelectUserRow = exports.keyExtractor = void 0;
var components_1 = require("@shared/components");
var channel_1 = require("@shared/components/blocks/contexts/channel");
var form_1 = require("@shared/components/blocks/contexts/form");
var field_1 = require("@shared/components/blocks/lib/field");
var fieldAssignee_1 = require("@shared/components/blocks/lib/fieldAssignee");
var hooks_1 = require("@shared/components/blocks/lib/fieldAssignee/hooks");
var userIcon_1 = require("@shared/components/blocks/lib/fieldAssignee/userIcon");
var label_1 = require("@shared/components/blocks/utils/label");
var modal_1 = require("@shared/components/multiSelect/modal");
var typography_1 = require("@shared/components/typography");
var styles_1 = require("@shared/styles");
var alert_1 = require("@shared/util/alert");
var hooks_2 = require("@shared/util/hooks");
var i18n_1 = require("@shared/util/i18n");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var utils_1 = require("../../utils");
var base_1 = require("../input/base");
var utils_2 = require("../input/utils");
var batchOptions_1 = require("./batchOptions");
var hooks_3 = require("./hooks");
var lodash_1 = require("lodash");
var keyExtractor = function (user) { return String(user.id); };
exports.keyExtractor = keyExtractor;
var MultiSelectUserRow = function (_a) {
    var user = _a.user;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    return (react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, paddingVertical: 1 },
        react_1.default.createElement(components_1.MBUserImage, { style: styles.userImage, user: user }),
        react_1.default.createElement(typography_1.MBTextBody1, { color: colors.foregroundActive }, user.fullName)));
};
exports.MultiSelectUserRow = MultiSelectUserRow;
var MBCardInputBlockAssignee = function (_a) {
    var _b, _c;
    var block = _a.block;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var channel = (0, react_1.useContext)(channel_1.CardChannelContext);
    var _d = (0, react_1.useContext)(form_1.CardFormContext), setFieldValue = _d.setFieldValue, values = _d.values;
    var selectedUserIds = (0, react_1.useMemo)(function () { return values[block.name] || []; }, [values[block.name]]);
    var selectedUsers = (0, hooks_1.useResolveAssignees)(selectedUserIds);
    var onChange = (0, react_1.useCallback)(function (users) {
        return setFieldValue(block.name, (0, lodash_1.isEmpty)(users) ? null : users.map(function (u) { return u.id; }));
    }, [block.name, setFieldValue]);
    var users = (0, hooks_2.useChannelUsers)(channel === null || channel === void 0 ? void 0 : channel.id);
    var color = selectedUsers.length ? colors.foregroundActive : colors.foregroundInactive;
    var content = (0, react_1.useMemo)(function () {
        return selectedUsers.length > 0
            ? (0, fieldAssignee_1.fieldPresenter)(selectedUsers, block.fieldType, block.textStyle, color)
            : (0, field_1.defaultFieldPresenter)(block.placeholder, block.fieldType, block.textStyle, color);
    }, [block, selectedUsers, color]);
    var renderLeft = (0, react_1.useMemo)(function () { return (!!selectedUsers.length ? function () { return react_1.default.createElement(userIcon_1.MBAssigneeUserIcon, { users: selectedUsers }); } : null); }, [selectedUsers]);
    var renderRight = (0, react_1.useMemo)(function () { return (!!selectedUsers.length ? utils_2.renderClearButton : null); }, [selectedUsers]);
    var renderLabel = (0, react_1.useMemo)(function () { return function () {
        if (!selectedUsers.length) {
            return null;
        }
        if (values.batchByAssignee && block.batchLabel) {
            return react_1.default.createElement(label_1.MBBlockLabel, { label: block.batchLabel });
        }
        if (!!block.label) {
            return react_1.default.createElement(label_1.MBBlockLabel, { label: block.label });
        }
        return null;
    }; }, [selectedUsers, values.batchByAssignee, block.batchLabel]);
    var match = (0, react_1.useCallback)(function (user, query) {
        var lowercaseQuery = query.toLowerCase();
        return (user.username.toLowerCase().includes(lowercaseQuery) ||
            user.fullName.toLowerCase().includes(lowercaseQuery));
    }, []);
    var updateBatchName = (0, react_1.useCallback)(function (value) { return setFieldValue('batchByAssigneeName', value); }, [setFieldValue]);
    var recurrenceEnabled = !!values.recurrence;
    var toggleBatchByAssignee = (0, react_1.useCallback)(function (enabled) { return __awaiter(void 0, void 0, void 0, function () {
        var confirmed;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(enabled && recurrenceEnabled)) return [3 /*break*/, 2];
                    return [4 /*yield*/, alert_1.MBAlert.confirm({
                            title: (0, i18n_1.i18n)('card.blocks.assigneeInput.recurrenceAlert.title'),
                            message: (0, i18n_1.i18n)('card.blocks.assigneeInput.recurrenceAlert.message'),
                            confirmText: (0, i18n_1.i18n)('card.blocks.assigneeInput.recurrenceAlert.confirmText'),
                        })];
                case 1:
                    confirmed = _a.sent();
                    if (!confirmed) {
                        return [2 /*return*/];
                    }
                    setFieldValue('recurrence', null);
                    _a.label = 2;
                case 2:
                    setFieldValue('batchByAssignee', enabled);
                    return [2 /*return*/];
            }
        });
    }); }, [setFieldValue, values, recurrenceEnabled]);
    (0, hooks_3.useCreateBatchTagOnSubmit)(block);
    var TypoComponent = (0, utils_1.getTypoComponent)(block.textStyle);
    if (block.allowBatch && !block.batchTagFieldName) {
        return (react_1.default.createElement(TypoComponent, { color: colors.error.foreground }, "Warning: cannot render assignee input because allowBatch is enabled, but no batchTagFieldName has been defined in the input block."));
    }
    return (react_1.default.createElement(modal_1.MBMultiSelectModal, { label: block.label, onChange: onChange, selectAll: true, renderSelectAll: function () { return (react_1.default.createElement(components_1.MBView, { flex: true, row: true, centerVertically: true, paddingVertical: 1 },
            !(0, lodash_1.isNil)(channel) && react_1.default.createElement(components_1.MBChannelImage, { channel: channel, style: styles.userImage }),
            react_1.default.createElement(typography_1.MBTextBody1, null, (0, i18n_1.i18n)('form.placeholder.everyone')))); }, header: !!block.allowBatch && (react_1.default.createElement(batchOptions_1.BatchOptions, { enabled: !!values.batchByAssignee, name: (_b = values.batchByAssigneeName) !== null && _b !== void 0 ? _b : values.name, onToggle: toggleBatchByAssignee, onChangeName: updateBatchName })), max: (_c = block.validation) === null || _c === void 0 ? void 0 : _c.max, renderItem: function (user) { return react_1.default.createElement(exports.MultiSelectUserRow, { user: user }); }, values: users, selectedValues: selectedUsers, match: match, keyExtractor: exports.keyExtractor },
        react_1.default.createElement(base_1.MBCardInputBlockBase, { block: block, renderLabel: renderLabel, renderLeft: renderLeft, renderRight: renderRight }, content)));
};
exports.MBCardInputBlockAssignee = MBCardInputBlockAssignee;
var styles = react_native_1.StyleSheet.create({
    userImage: {
        width: (0, components_1.grid)(5),
        height: (0, components_1.grid)(5),
        borderRadius: (0, components_1.grid)(2.5),
        overflow: 'hidden',
        marginRight: (0, components_1.grid)(2),
    },
});
