"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createApolloClient = exports.DISABLE_CACHE_ENTITIES = exports.setSharedApolloClient = exports.webSocketLink = exports.apolloClient = void 0;
exports.reconnectWebsocket = reconnectWebsocket;
// @ts-strict-ignore
var client_1 = require("@apollo/client");
var batch_http_1 = require("@apollo/client/link/batch-http");
var http_1 = require("@apollo/client/link/http");
var utilities_1 = require("@apollo/client/utilities");
var config_1 = require("@shared/config");
var hooks_1 = require("@shared/scenes/channel/options/hooks");
var sentry_1 = require("@shared/util/sentry");
var lodash_1 = __importStar(require("lodash"));
var WebSocketLink_1 = require("./client/WebSocketLink");
var customApolloLinks_1 = require("./customApolloLinks");
/*
    In shared components, we want to be able to run GQL queries that
    aren't attached directly to components. In web/native we only
    rely on react-apollo's HOCs for mutations and queries. This makes
    reusability hard. Therefore we expose the actual apollo client
    to be used in dispatch actions.
*/
exports.apolloClient = undefined;
exports.webSocketLink = null;
function reconnectWebsocket() {
    exports.webSocketLink === null || exports.webSocketLink === void 0 ? void 0 : exports.webSocketLink.client.terminate(); // non-fatal closing will attempt to be re-opened
}
/*
    To use the apollo client, we need to get it supplied by where it
    is configured in web/native. We add auth headers etc there and
    want to use the exact same client as is being used there. This
    semi-hack is a way to "send" that client object over into the
    shared component world so that we can use it freely here.
    Note: this is expected to be called before apolloClient (above)
    is used by shared components.
*/
var setSharedApolloClient = function (client) {
    exports.apolloClient = client;
};
exports.setSharedApolloClient = setSharedApolloClient;
/**
 * Some entities in WorkflowTemplate have 'ids' that are not unique across the app.
 * This causes Apollo Client to try to "cache" them and might cause
 * data inconsistency problems.
 */
exports.DISABLE_CACHE_ENTITIES = [
    // workflow components
    'AddressComponent',
    'AuditCheckboxComponent',
    'AuditTagComponent',
    'AuditTextComponent',
    'CombinedTagsComponent',
    'DateComponent',
    'EmailComponent',
    'EntityBatchComponent',
    'FileComponent',
    'GeolocationComponent',
    'InputButtonComponent',
    'NumberComponent',
    'PersonComponent',
    'ReferencedInComponent',
    'RelatedCardComponent',
    'RelatedCardLookupComponent',
    'ScheduledAutomationComponent',
    'SignatureComponent',
    'StaticTextComponent',
    'SubformComponent',
    'TagComponent',
    'TextComponent',
    'TimeTrackerComponent',
    'TodoComponent',
    // other
    'ViewTemplate',
];
var createApolloClient = function (responseHeadersLinks, connectivityNotifier, customRequestHeaders) {
    if (responseHeadersLinks === void 0) { responseHeadersLinks = []; }
    if (customRequestHeaders === void 0) { customRequestHeaders = function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, ({})];
    }); }); }; }
    var queryLoggerLink = new client_1.ApolloLink(function (operation, forward) {
        sentry_1.MBSentry.breadcrumbFromApolloOperation(operation);
        return forward(operation);
    });
    exports.webSocketLink = (0, WebSocketLink_1.createWebSocketLink)(connectivityNotifier);
    var fetchNotifyOffline = function (input, init) {
        var p = fetch(input, init);
        p.catch(function (reason) {
            if (reason.name !== 'AbortError') {
                connectivityNotifier.offline('fetch::error');
            }
        });
        return p;
    };
    var uri = "".concat(config_1.Config.API_URL, "/api/graphql");
    var httpLink = new http_1.HttpLink({ uri: uri, fetch: fetchNotifyOffline });
    var batchHttpLink = new batch_http_1.BatchHttpLink({ uri: uri });
    var queriesAndMutationsLink = (0, client_1.split)(function (operation) { return operation.operationName === 'ListCardsQuery'; }, batchHttpLink, httpLink);
    // Split queries based on if it's a subscription or not.
    // This is copy pasted from the Apollo docs:
    // https://www.apollographql.com/docs/react/advanced/subscriptions/#client-setup
    var dataLink = (0, client_1.split)(
    // split based on operation type
    function (operation) {
        var def = (0, utilities_1.getMainDefinition)(operation.query);
        return def.kind === 'OperationDefinition' && def.operation === 'subscription';
    }, exports.webSocketLink, queriesAndMutationsLink);
    exports.apolloClient = new client_1.ApolloClient({
        link: (0, client_1.from)(__spreadArray(__spreadArray([
            queryLoggerLink,
            (0, customApolloLinks_1.customRequestHeadersLink)(customRequestHeaders),
            (0, customApolloLinks_1.authLink)(),
            // Note(fant): this error link needs to be before the data link
            // https://stackoverflow.com/a/55169771
            customApolloLinks_1.errorLoggerLink,
            customApolloLinks_1.removeTypenameFromMutationLink
        ], responseHeadersLinks, true), [
            dataLink,
        ], false)),
        assumeImmutableResults: true,
        cache: new client_1.InMemoryCache({
            possibleTypes: require('./possibleTypes.json'),
            dataIdFromObject: function (object) {
                if (object.__typename === 'Channel') {
                    return "".concat(object.__typename).concat(object.slug);
                }
                if (!!object.id && !!object.__typename) {
                    return "".concat(object.__typename).concat(object.id);
                }
                return null;
            },
            // https://www.apollographql.com/docs/react/local-state/managing-state-with-field-policies/
            typePolicies: __assign({ Channel: {
                    fields: {
                        name: {
                            merge: function (existing, incoming, _a) {
                                var variables = _a.variables;
                                return lodash_1.default.isNil(existing) || !isCoreApiGetChannelQuery(variables)
                                    ? incoming
                                    : existing;
                            },
                        },
                        pinnedSortKey: {
                            read: function (_ignored, _a) {
                                var readField = _a.readField;
                                var slug = readField('slug');
                                return (0, hooks_1.pinnedSortKeyByChannelSlug)()[slug] || null;
                            },
                        },
                    },
                }, Query: {
                    fields: {
                        listViewTemplatesV2: {
                            keyArgs: ['input', ['workflowTemplateId', 'type', 'subtype', 'isPublic', 'name']],
                            merge: function (existing, incoming, _a) {
                                var args = _a.args;
                                var offset = args === null || args === void 0 ? void 0 : args.input.offset;
                                if ((0, lodash_1.isUndefined)(offset)) {
                                    return incoming;
                                }
                                return mergeLists({ listPath: 'viewTemplates', existing: existing, incoming: incoming, offset: offset });
                            },
                        },
                        listWorkflowTemplateAutomations: {
                            keyArgs: ['input', ['workflowTemplateId', 'trigger', 'name']],
                            merge: function (existing, incoming, _a) {
                                var args = _a.args;
                                var offset = args === null || args === void 0 ? void 0 : args.input.offset;
                                if ((0, lodash_1.isUndefined)(offset)) {
                                    return incoming;
                                }
                                return mergeLists({ listPath: 'automations', existing: existing, incoming: incoming, offset: offset });
                            },
                        },
                        listCards: {
                            keyArgs: [
                                'input',
                                [
                                    'workflowTemplateId',
                                    'relationFilters',
                                    'legacyRelationFilters',
                                    'channelId',
                                    'startDateAfter',
                                    'startDateBefore',
                                    'order',
                                    ['orderBy', 'orderDirection'],
                                ],
                            ],
                            merge: function (existing, incoming, _a) {
                                var _b;
                                var readField = _a.readField, mergeObjects = _a.mergeObjects, args = _a.args;
                                // indicates query cache was adjusted externally, don't merge
                                if (((_b = args.input) === null || _b === void 0 ? void 0 : _b.offset) === 0) {
                                    return incoming;
                                }
                                // paginated, go ahead and merge
                                var merged = __assign(__assign({}, incoming), { cards: existing ? __spreadArray([], existing.cards, true) : [] });
                                var cardIndexes = {};
                                if (existing) {
                                    existing.cards.forEach(function (card, index) {
                                        cardIndexes[readField('id', card)] = index;
                                    });
                                }
                                incoming.cards.forEach(function (card) {
                                    var id = readField('id', card);
                                    var index = cardIndexes[id];
                                    if (typeof index === 'number') {
                                        merged.cards[index] = mergeObjects(merged.cards[index], card);
                                    }
                                    else {
                                        cardIndexes[id] = merged.cards.length;
                                        merged.cards.push(card);
                                    }
                                });
                                return merged;
                            },
                        },
                        listWorkflowEntities: {
                            keyArgs: [
                                'input',
                                ['workflowTemplateId', 'sortByComponents', 'filterByComponents', 'omniSearchQuery'],
                            ],
                            merge: function (existing, incoming, _a) {
                                var _b, _c;
                                var readField = _a.readField, mergeObjects = _a.mergeObjects, args = _a.args;
                                // setting a limit of 0 is used to query for an updated count,
                                // without affecting existing entities
                                if (((_b = args.input) === null || _b === void 0 ? void 0 : _b.limit) === 0) {
                                    return __assign(__assign({}, existing), { totalResults: incoming.totalResults });
                                }
                                // indicates query cache was adjusted externally, don't merge
                                if (((_c = args.input) === null || _c === void 0 ? void 0 : _c.offset) === 0) {
                                    return incoming;
                                }
                                // paginated, go ahead and merge
                                var merged = __assign(__assign({}, incoming), { entities: existing ? __spreadArray([], existing.entities, true) : [], refs: {
                                        __typename: 'EntityRefs',
                                        personRefs: existing ? __spreadArray([], (existing.refs.personRefs || []), true) : [],
                                        workflowEntityRefs: existing
                                            ? __spreadArray([], (existing.refs.workflowEntityRefs || []), true) : [],
                                        workflowTemplateRefs: existing
                                            ? __spreadArray([], (existing.refs.workflowTemplateRefs || []), true) : [],
                                        recurringEntitiesScheduleRefs: existing
                                            ? __spreadArray([], (existing.refs.recurringEntitiesScheduleRefs || []), true) : [],
                                    } });
                                // dedupe and add/merge
                                var dedupeAndAdd = function (path) {
                                    var indexes = {};
                                    if (existing) {
                                        var existingPath = lodash_1.default.get(existing, path);
                                        existingPath === null || existingPath === void 0 ? void 0 : existingPath.forEach(function (card, index) {
                                            indexes[readField('id', card)] = index;
                                        });
                                    }
                                    var incomingPath = lodash_1.default.get(incoming, path);
                                    var mergedPath = lodash_1.default.get(merged, path);
                                    incomingPath === null || incomingPath === void 0 ? void 0 : incomingPath.forEach(function (entity) {
                                        var id = readField('id', entity);
                                        var index = indexes[id];
                                        if (typeof index === 'number') {
                                            mergedPath[index] = mergeObjects(mergedPath[index], entity);
                                        }
                                        else {
                                            indexes[id] = mergedPath.length;
                                            mergedPath.push(entity);
                                        }
                                    });
                                };
                                dedupeAndAdd('entities');
                                dedupeAndAdd('refs.personRefs');
                                dedupeAndAdd('refs.workflowEntityRefs');
                                dedupeAndAdd('refs.workflowTemplateRefs');
                                dedupeAndAdd('refs.recurringEntitiesScheduleRefs');
                                return merged;
                            },
                        },
                        listWorkflowTemplatesV2: {
                            keyArgs: [
                                'input',
                                ['isPublic', 'isSubform', 'ids', 'name', 'relatedWorkflowTemplateId'],
                            ],
                            merge: function (existing, incoming, _a) {
                                var args = _a.args;
                                var offset = args === null || args === void 0 ? void 0 : args.input.offset;
                                if ((0, lodash_1.isUndefined)(offset)) {
                                    return incoming;
                                }
                                return mergeLists({ listPath: 'workflowTemplates', existing: existing, incoming: incoming, offset: offset });
                            },
                        },
                    },
                } }, exports.DISABLE_CACHE_ENTITIES.reduce(function (acc, cur) {
                acc[cur] = { keyFields: false };
                return acc;
            }, {})),
        }),
        queryDeduplication: true,
        defaultOptions: {
            query: { fetchPolicy: 'cache-first' },
        },
    });
    return exports.apolloClient;
};
exports.createApolloClient = createApolloClient;
function isCoreApiGetChannelQuery(variables) {
    var keys = Object.keys(variables);
    if (keys.length === 2 && keys.includes('slug') && keys.includes('id')) {
        return true;
    }
    return false;
}
var mergeLists = function (_a) {
    var _b;
    var listPath = _a.listPath, existing = _a.existing, incoming = _a.incoming, _c = _a.offset, offset = _c === void 0 ? 0 : _c;
    var existingList = !(0, lodash_1.isNil)(existing === null || existing === void 0 ? void 0 : existing[listPath]) ? existing[listPath] : [];
    var incomingList = incoming[listPath];
    var mergedList = __spreadArray([], existingList, true);
    for (var i = 0; i < incomingList.length; ++i) {
        mergedList[offset + i] = incomingList[i];
    }
    var merged = __assign(__assign({}, incoming), (_b = {}, _b[listPath] = mergedList, _b));
    return merged;
};
