"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBulkEditMenuItem = useBulkEditMenuItem;
var actionSheet_1 = require("@shared/util/actionSheet");
var i18n_1 = require("@shared/util/i18n");
var react_1 = require("react");
var lodash_1 = require("lodash");
var useNavigator_1 = require("@shared/util/navigation/useNavigator");
function useBulkEditMenuItem(_a) {
    var cardId = _a.cardId, channelSlug = _a.channelSlug, workflowTemplateId = _a.workflowTemplateId, recurringEntitiesScheduleId = _a.recurringEntitiesScheduleId;
    var navigate = (0, useNavigator_1.useNavigator)().navigate;
    var navigateToBulkUpdate = (0, react_1.useCallback)(function (allOrUpcoming) {
        if ((0, lodash_1.isString)(channelSlug) &&
            (0, lodash_1.isString)(workflowTemplateId) &&
            (0, lodash_1.isString)(recurringEntitiesScheduleId)) {
            navigate('CardBulkUpdate', {
                cardId: cardId,
                channelSlug: channelSlug,
                workflowTemplateId: workflowTemplateId,
                recurringEntitiesScheduleId: recurringEntitiesScheduleId,
                allOrUpcoming: allOrUpcoming,
            });
        }
    }, [navigate, cardId, channelSlug, workflowTemplateId, recurringEntitiesScheduleId]);
    return (0, react_1.useCallback)(function (anchor) {
        actionSheet_1.MBActionSheet.show({
            parent: anchor,
            title: (0, i18n_1.i18n)('card.bulkUpdate.menu.submenu.title'),
            webHorizontalAlignment: 'right',
            options: [
                {
                    title: (0, i18n_1.i18n)('card.bulkUpdate.menu.submenu.upcomingCards'),
                    onPress: function () { return navigateToBulkUpdate('upcoming'); },
                },
                {
                    title: (0, i18n_1.i18n)('card.bulkUpdate.menu.submenu.allCards'),
                    onPress: function () { return navigateToBulkUpdate('all'); },
                },
            ],
        });
    }, [navigateToBulkUpdate]);
}
