"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelatedEntityEditField = RelatedEntityEditField;
var react_1 = __importStar(require("react"));
var RelatedEntityField_1 = require("@shared/data/entity/fields/RelatedEntityField");
var components_1 = require("@shared/components");
var react_native_1 = require("react-native");
var lodash_1 = require("lodash");
var EditFieldContainer_1 = require("../EditFieldContainer");
var RelatedEntityList_1 = require("./RelatedEntityList");
var hooks_1 = require("@shared/util/hooks");
var RelatedEntityPicker_1 = require("./RelatedEntityPicker");
var hooks_2 = require("@shared/scenes/workflows/hooks");
function RelatedEntityEditField(_a) {
    var component = _a.component, field = _a.field, updateField = _a.updateField, fields = _a.fields;
    var containerRef = (0, react_1.useRef)(null);
    var _b = (0, hooks_1.useBoolean)(false), isPickerOpen = _b[0], openPicker = _b[1], closePicker = _b[2];
    var entities = (0, react_1.useMemo)(function () { var _a; return (_a = field === null || field === void 0 ? void 0 : field.relatedEntities) !== null && _a !== void 0 ? _a : []; }, [field]);
    var onChange = (0, react_1.useCallback)(function (updatedEntities) {
        var updatedItems = updatedEntities.map(function (entity) { return ({ id: entity.id }); });
        updateField(component.id, new RelatedEntityField_1.RelatedEntityField(updatedItems, updatedEntities));
    }, [component.id, updateField]);
    var onClear = (0, react_1.useCallback)(function () {
        onChange([]);
    }, [onChange]);
    var hasEntities = !(0, lodash_1.isEmpty)(entities);
    var label = getLabelWithCount(component, entities.length);
    var isReadOnly = !!component.readonly;
    var showClearButton = hasEntities && !isReadOnly;
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(react_native_1.TouchableOpacity, { ref: containerRef }),
        react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: isReadOnly ? undefined : openPicker },
            react_1.default.createElement(EditFieldContainer_1.EditFieldContainer, { component: component, label: label, showLabel: hasEntities, showPlaceholder: !hasEntities, onClear: showClearButton ? onClear : undefined }, hasEntities && react_1.default.createElement(RelatedEntityList_1.RelatedEntityList, { entities: entities, onShowMore: openPicker }))),
        isPickerOpen && (react_1.default.createElement(RelatedEntityPicker_1.RelatedEntityPicker, { entities: entities, onChange: onChange, onClose: closePicker, containerRef: containerRef, component: component, fields: fields }))));
}
function getLabelWithCount(component, count) {
    if (count > 0) {
        var componentLabel = (0, hooks_2.getComponentLabel)(component);
        return "".concat(componentLabel, " (").concat(count, ")").trim();
    }
    else {
        return undefined;
    }
}
