"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBatchSchedule = useBatchSchedule;
var utils_1 = require("@shared/scenes/entity/components/fields/DateEditField/utils");
var lodash_1 = require("lodash");
function useBatchSchedule(_a) {
    var date = _a.date, count = _a.count, setCount = _a.setCount, setPresetIndexSelected = _a.setPresetIndexSelected, setRruleOptions = _a.setRruleOptions, setShowingCustomOptions = _a.setShowingCustomOptions, presets = _a.presets;
    var handleCountChange = function (newCount) {
        if (newCount === '') {
            setCount === null || setCount === void 0 ? void 0 : setCount(undefined);
            return;
        }
        var numCount = Number(newCount);
        var isNumber = !isNaN(numCount);
        if (isNumber) {
            setCount === null || setCount === void 0 ? void 0 : setCount(numCount);
        }
    };
    var handleFreqSelect = function (newFreq) {
        setPresetIndexSelected(0);
        setRruleOptions(presets.filter(function (p) { return p.freq === newFreq; })[0]);
    };
    var handlePresetSelect = function (preset, index) {
        setPresetIndexSelected(index);
        setRruleOptions(preset);
    };
    var handleCustomSelect = function () {
        setPresetIndexSelected(-1);
        setRruleOptions(function (prev) {
            var _a;
            if (!(0, lodash_1.isNil)(prev)) {
                return __assign(__assign({}, prev), { interval: (_a = prev.interval) !== null && _a !== void 0 ? _a : 1 });
            }
            return (0, utils_1.buildCustomRRule)((0, utils_1.getSharedOptions)(date, count), {
                interval: 1,
            });
        });
        setShowingCustomOptions === null || setShowingCustomOptions === void 0 ? void 0 : setShowingCustomOptions(true);
    };
    return {
        handleCountChange: handleCountChange,
        handleFreqSelect: handleFreqSelect,
        handlePresetSelect: handlePresetSelect,
        handleCustomSelect: handleCustomSelect,
    };
}
