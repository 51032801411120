"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBreakpointOnWidthExceeds = exports.useDidPropChange = exports.usePersistentRef = exports.useComponentDidMount = exports.usePrevious = exports.useChannelUsers = void 0;
exports.useWindowSize = useWindowSize;
exports.useIsDesktop = useIsDesktop;
exports.useIsMobile = useIsMobile;
exports.useChannel = useChannel;
exports.useGetChannelLazyQuery = useGetChannelLazyQuery;
exports.useChannelId = useChannelId;
exports.useIsMountedState = useIsMountedState;
exports.useTrigger = useTrigger;
exports.useExecuteOnTrigger = useExecuteOnTrigger;
exports.useToggle = useToggle;
// @ts-strict-ignore
var client_1 = require("@apollo/client");
var api_1 = require("@shared/api");
var useFragment_1 = require("@shared/api/useFragment");
var lodash_1 = require("lodash");
var react_1 = require("react");
var react_native_1 = require("react-native");
var DESKTOP_VIEWPORT_WIDTH_THRESHOLD = 840;
// For reference: https://usehooks.com/useWindowSize
function useWindowSize() {
    var getSize = function () { return ({
        width: react_native_1.Platform.OS === 'web' ? window.innerWidth : react_native_1.Dimensions.get('screen').width,
        height: react_native_1.Platform.OS === 'web' ? window.innerHeight : react_native_1.Dimensions.get('screen').height,
    }); };
    var _a = (0, react_1.useState)(getSize), windowSize = _a[0], setWindowSize = _a[1];
    (0, react_1.useEffect)(function () {
        if (react_native_1.Platform.OS !== 'web') {
            return;
        }
        var handleResize = function () { return setWindowSize(getSize()); };
        window.addEventListener('resize', handleResize);
        return function () { return window.removeEventListener('resize', handleResize); };
    }, []);
    return windowSize;
}
function useIsDesktop() {
    var width = useWindowSize().width;
    return react_native_1.Platform.OS === 'web' && width >= DESKTOP_VIEWPORT_WIDTH_THRESHOLD;
}
function useIsMobile() {
    var width = useWindowSize().width;
    return react_native_1.Platform.OS !== 'web' || width < DESKTOP_VIEWPORT_WIDTH_THRESHOLD;
}
/**
 * @deprecated Please use useGetChannel hook or PermissionsProvider.
 * BEWARE, useGetChannel does not return the same things (like users).
 */
function useChannel(channelSlug) {
    return (0, useFragment_1.useFragment)({
        id: channelSlug,
        skip: !channelSlug,
        fragment: api_1.Fragments.channel.listItem,
        fallbackQuery: api_1.Queries.channel.listItem,
        fallbackQueryVariables: { slug: channelSlug },
    });
}
function useGetChannelLazyQuery() {
    var _this = this;
    var getChannel = (0, client_1.useLazyQuery)(api_1.Queries.channel.getChannel)[0];
    return (0, react_1.useCallback)(function (params) { return __awaiter(_this, void 0, void 0, function () {
        var variables, response;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!(!(0, lodash_1.isNil)(params.id) || !(0, lodash_1.isNil)(params.slug))) return [3 /*break*/, 2];
                    variables = { id: params.id, slug: params.slug };
                    return [4 /*yield*/, getChannel({ variables: variables })];
                case 1:
                    response = _b.sent();
                    return [2 /*return*/, (_a = response.data) === null || _a === void 0 ? void 0 : _a.channel];
                case 2: return [2 /*return*/];
            }
        });
    }); }, [getChannel]);
}
var useChannelUsers = function (channelId) {
    var _a;
    var data = (0, client_1.useQuery)(api_1.Queries.channel.listChannelMemberships, {
        variables: { input: { channelId: channelId, onlyAssignableUsers: true } },
        skip: (0, lodash_1.isNil)(channelId),
    }).data;
    var users = (_a = data === null || data === void 0 ? void 0 : data.listChannelMemberships) === null || _a === void 0 ? void 0 : _a.map(function (membership) { return membership.user; });
    return (0, lodash_1.isEmpty)(users) ? [] : users;
};
exports.useChannelUsers = useChannelUsers;
function useChannelId(channelSlug) {
    var _a;
    return (_a = useChannel(channelSlug)) === null || _a === void 0 ? void 0 : _a.id;
}
var usePrevious = function (val) {
    var prev = (0, react_1.useRef)();
    (0, react_1.useEffect)(function () {
        prev.current = val;
    });
    return prev.current;
};
exports.usePrevious = usePrevious;
/**
 * @deprecated Invokes a callback when the component is mounted. Please use
 * useEffect instead, so callback dependencies can be statically analyzed.
 **/
var useComponentDidMount = function (callback) {
    (0, react_1.useEffect)(callback, []);
};
exports.useComponentDidMount = useComponentDidMount;
var usePersistentRef = function (val) {
    var ref = (0, react_1.useRef)(val);
    ref.current = val;
    return ref;
};
exports.usePersistentRef = usePersistentRef;
var useDidPropChange = function (val, callback) {
    var prev = (0, exports.usePrevious)(val);
    (0, react_1.useEffect)(function () {
        if (prev !== val) {
            callback();
        }
    }, [prev, val, callback]);
};
exports.useDidPropChange = useDidPropChange;
function useIsMountedState() {
    var _a = (0, react_1.useState)(false), mounted = _a[0], setMounted = _a[1];
    (0, react_1.useEffect)(function () {
        setMounted(true);
        return function () { return setMounted(false); };
    }, []);
    return mounted;
}
/**
 * Manages a boolean breakpoint when resizing an MBView component in a way
 * that does not cause extra re-renders. It only toggles the breakpoint
 * when the value crosses the threshold regardless of how often onSize is invoked.
 * @param threshold - when width is above this value, breakpoint will be true
 * @param initialValue - initial value of breakpoint
 */
var useBreakpointOnWidthExceeds = function (threshold, initialValue) {
    var _a = (0, react_1.useState)(initialValue), breakpoint = _a[0], setBreakpoint = _a[1];
    var breakpointRef = (0, react_1.useRef)(initialValue);
    var handleOnSize = (0, react_1.useCallback)(function (_a) {
        var width = _a.width;
        if (width <= threshold && breakpointRef.current === true) {
            breakpointRef.current = false;
            setBreakpoint(false);
        }
        else if (width > threshold && breakpointRef.current === false) {
            breakpointRef.current = true;
            setBreakpoint(true);
        }
    }, [threshold]);
    return (0, react_1.useMemo)(function () { return ({ handleOnSize: handleOnSize, breakpoint: breakpoint }); }, [handleOnSize, breakpoint]);
};
exports.useBreakpointOnWidthExceeds = useBreakpointOnWidthExceeds;
/**
 * This hook sets up state and callback tuple that allows a parent component to invoke a callback within
 * a child component without using refs or inversion contexts. This is accomplished by simply incrementing
 * a number and passing that as a prop to a child component. Inside the child component, the companion hook
 * (useExecuteOnTrigger) is used to detect the number incrementing and then invoke a function as needed.
 */
function useTrigger() {
    var _a = (0, react_1.useState)(0), trigger = _a[0], setTrigger = _a[1];
    var onTrigger = (0, react_1.useCallback)(function () { return setTrigger(trigger + 1); }, [trigger]);
    return [trigger, onTrigger];
}
/**
 * To be used inside a child component who's parent component is using useTrigger(). Note that
 * if the callback changes over time, it may be invoked if the trigger is non-zero. Please ensure
 * your callback is memoised or static.
 */
function useExecuteOnTrigger(trigger, callback) {
    (0, react_1.useEffect)(function () {
        if (trigger > 0) {
            callback();
        }
    }, [trigger, callback]);
}
function useToggle(defaultIsOn) {
    if (defaultIsOn === void 0) { defaultIsOn = false; }
    var _a = (0, react_1.useState)(defaultIsOn), isOn = _a[0], setToggle = _a[1];
    var toggleOn = (0, react_1.useCallback)(function () { return setToggle(true); }, []);
    var toggleOff = (0, react_1.useCallback)(function () { return setToggle(false); }, []);
    var toggle = (0, react_1.useCallback)(function () { return setToggle(function (prevIsOn) { return !prevIsOn; }); }, []);
    return (0, react_1.useMemo)(function () { return ({ isOn: isOn, toggleOn: toggleOn, toggleOff: toggleOff, toggle: toggle, setToggle: setToggle }); }, [isOn, toggleOn, toggleOff, toggle, setToggle]);
}
