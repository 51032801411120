"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useScrollTextInputIntoView = useScrollTextInputIntoView;
exports.useDebouncedScrollTextInputIntoView = useDebouncedScrollTextInputIntoView;
var react_1 = require("react");
var react_native_1 = require("react-native");
var scroll_1 = require("@shared/components/blocks/contexts/scroll");
var lodash_1 = require("lodash");
var use_debounce_1 = require("use-debounce");
var keyboardAnimationDuration = 500;
function useScrollTextInputIntoView() {
    var scrollViewRef = (0, scroll_1.useCardBlockScrollContext)();
    return (0, react_1.useCallback)(function () {
        scrollActiveElementIntoView(scrollViewRef === null || scrollViewRef === void 0 ? void 0 : scrollViewRef.current);
    }, [scrollViewRef]);
}
function useDebouncedScrollTextInputIntoView(delay) {
    if (delay === void 0) { delay = keyboardAnimationDuration; }
    var scrollIntoView = useScrollTextInputIntoView();
    var debouncedScrollIntoView = (0, use_debounce_1.useDebouncedCallback)(scrollIntoView, delay)[0];
    return debouncedScrollIntoView;
}
function scrollActiveElementIntoView(scrollView) {
    var _a;
    if (react_native_1.Platform.OS === 'web') {
        (_a = document.activeElement) === null || _a === void 0 ? void 0 : _a.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'nearest',
        });
    }
    else if (react_native_1.Platform.OS === 'ios') {
        var currentlyFocusedInput = react_native_1.TextInput.State.currentlyFocusedInput();
        if (!(0, lodash_1.isNil)(scrollView) && !(0, lodash_1.isNil)(currentlyFocusedInput)) {
            var component = (0, react_native_1.findNodeHandle)(currentlyFocusedInput);
            scrollView.scrollResponderScrollNativeHandleToKeyboard(component, 175, true);
        }
    }
}
