"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileInput = FileInput;
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var react_native_1 = require("react-native");
var overlay_1 = require("@shared/components/touchable/overlay");
var dropTarget_1 = require("./dropTarget");
var InputBlockMedia_1 = require("./InputBlockMedia");
var hooks_1 = require("./hooks");
var label_1 = require("../../utils/label");
var styles_1 = require("@shared/styles");
var lodash_1 = require("lodash");
// displays file/image input with list of files
function FileInput(_a) {
    var files = _a.files, label = _a.label, placeholder = _a.placeholder, icon = _a.icon, error = _a.error, acceptedContentTypes = _a.acceptedContentTypes, onFileAdded = _a.onFileAdded, onFileRemoved = _a.onFileRemoved, _b = _a.mode, mode = _b === void 0 ? 'full' : _b;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var _c = (0, hooks_1.useFileUpload)({
        onFileAdded: onFileAdded,
        onFileRemoved: onFileRemoved,
        acceptedContentTypes: acceptedContentTypes,
    }), handleDrop = _c.handleDrop, showFilePicker = _c.showFilePicker, uploading = _c.uploading;
    var dropTargetStyle = mode === 'full' ? styles.fullDropTarget : styles.leanDropTarget;
    var containerProps = (0, react_1.useMemo)(function () {
        if (mode === 'full') {
            return {
                fullWidth: true,
                paddingVertical: 1,
                paddingHorizontal: 1,
            };
        }
        else {
            return {
                style: styles.leanContainer,
                paddingHorizontal: 1,
            };
        }
    }, [mode]);
    var previewStyle = (0, react_1.useMemo)(function () { return (mode === 'full' ? styles.fullPreview : styles.leanPreview); }, [mode]);
    var displayInitiator = !files.length || mode === 'lean';
    var activeDropTarget = (react_1.default.createElement(dropTarget_1.DropTarget, { style: dropTargetStyle, acceptedContentTypes: acceptedContentTypes, placeholder: placeholder !== null && placeholder !== void 0 ? placeholder : '', mode: mode, state: "active" }));
    return (react_1.default.createElement(components_1.MBView, __assign({}, containerProps),
        !(0, lodash_1.isNil)(label) && (react_1.default.createElement(components_1.MBView, { paddingLeft: 3, paddingVertical: 1 },
            react_1.default.createElement(label_1.MBBlockLabel, { label: label }))),
        react_1.default.createElement(components_1.MBFileDropListener, { onFilesDropped: handleDrop, fill: true, overlay: activeDropTarget },
            !(0, lodash_1.isEmpty)(files) && (react_1.default.createElement(components_1.MBView, null,
                react_1.default.createElement(components_1.MBButton, { onPress: function () {
                        showFilePicker();
                    }, wrap: true, disabled: uploading, theme: 'tertiary', title: placeholder, leftIcon: icon, leftIconSize: 24, loading: uploading, borderColor: colors.input.borderInactive }),
                react_1.default.createElement(InputBlockMedia_1.InputBlockMedia, { files: files, onRemoved: onFileRemoved, editable: !(0, lodash_1.isNil)(showFilePicker), style: previewStyle, compactFileView: false, horizontalPadding: 0, verticalPadding: 2 }))),
            displayInitiator && (react_1.default.createElement(overlay_1.MBTouchableOverlay, { onPress: showFilePicker, fill: true, overlay: activeDropTarget },
                react_1.default.createElement(dropTarget_1.DropTarget, { loading: uploading, style: dropTargetStyle, placeholder: placeholder !== null && placeholder !== void 0 ? placeholder : '', state: error ? 'error' : 'inactive', mode: mode, acceptedContentTypes: acceptedContentTypes }))))));
}
var styles = react_native_1.StyleSheet.create({
    leanContainer: {
        maxWidth: '60%',
        paddingBottom: (0, components_1.grid)(1),
    },
    leanDropTarget: {
        flexDirection: 'row',
    },
    fullDropTarget: {
        borderRadius: 8,
        borderStyle: 'dashed',
        borderWidth: 2,
    },
    fullPreview: { paddingHorizontal: (0, components_1.grid)(3) },
    leanPreview: {
        maxWidth: '60%',
        minHeight: 100,
    },
});
