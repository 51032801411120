"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useWorkspaceDetails = useWorkspaceDetails;
var hooks_1 = require("@shared/scenes/cards/allCardsList/hooks");
var notNil_1 = require("@shared/util/notNil");
var lodash_1 = require("lodash");
var react_1 = require("react");
function useWorkspaceDetails() {
    var channels = (0, hooks_1.useChannelList)().channels;
    return (0, react_1.useMemo)(function () {
        var workspaceDetails = new Map();
        channels === null || channels === void 0 ? void 0 : channels.forEach(function (channel) {
            var _a, _b;
            if (!(0, lodash_1.isNil)(channel) && !(0, lodash_1.isNil)(channel.workflowTemplateId)) {
                workspaceDetails.set(channel.workflowTemplateId, {
                    baseNavigationPath: channel.slug,
                    users: (_a = channel.users.filter(notNil_1.notNil)) !== null && _a !== void 0 ? _a : [],
                    id: channel.id,
                    name: channel.name,
                    imageUrl: (_b = channel.imageUrl) !== null && _b !== void 0 ? _b : '',
                });
            }
        });
        return workspaceDetails;
    }, [channels]);
}
