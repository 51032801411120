"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useEntityBatch = useEntityBatch;
var client_1 = require("@apollo/client");
var api_1 = require("@shared/api");
var lodash_1 = require("lodash");
function useEntityBatch(entityBatchId, workflowTemplateId) {
    return (0, client_1.useQuery)(api_1.Queries.entity.getRecurringEntitiesSchedule, {
        variables: { input: { id: entityBatchId !== null && entityBatchId !== void 0 ? entityBatchId : '', workflowTemplateId: workflowTemplateId !== null && workflowTemplateId !== void 0 ? workflowTemplateId : '' } },
        skip: (0, lodash_1.isNil)(entityBatchId) || (0, lodash_1.isNil)(workflowTemplateId),
    });
}
