"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBChatThreadInputBlock = void 0;
var react_1 = __importStar(require("react"));
var card_1 = require("@shared/scenes/chat/input/containers/input/card");
var scroll_1 = require("@shared/components/blocks/contexts/scroll");
var channel_1 = require("@shared/components/blocks/contexts/channel");
var lodash_1 = require("lodash");
var MBChatThreadInputBlock = function (_a) {
    var cardId = _a.cardId;
    var channel = (0, react_1.useContext)(channel_1.CardChannelContext);
    var channelSlug = channel === null || channel === void 0 ? void 0 : channel.slug;
    var scrollView = (0, react_1.useContext)(scroll_1.CardBlockScrollContext);
    if (!scrollView) {
        console.warn('Rendering card thread input block without CardBlockScrollContext being set. Did you forget to wrap these card blocks in CardBlockScrollProvider?');
    }
    var scrollToBottom = (0, react_1.useCallback)(function () {
        // Wait until the next run so that the newly sent message has been rendered
        setTimeout(function () { var _a; return (_a = scrollView === null || scrollView === void 0 ? void 0 : scrollView.current) === null || _a === void 0 ? void 0 : _a.scrollToEnd(); }, 0);
    }, [scrollView]);
    if ((0, lodash_1.isNil)(channelSlug)) {
        return null;
    }
    return (react_1.default.createElement(card_1.MBCardChatInput, { cardId: cardId, channelSlug: channelSlug, onSend: scrollToBottom, onFocus: scrollToBottom }));
};
exports.MBChatThreadInputBlock = MBChatThreadInputBlock;
