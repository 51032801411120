"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BatchDateFieldOption = void 0;
var hooks_1 = require("../../hooks");
var BatchDateFieldOption = /** @class */ (function () {
    function BatchDateFieldOption(title, icon, component, componentDependencies) {
        this.title = title;
        this.icon = icon;
        this.component = component;
        this.componentDependencies = componentDependencies;
        this.missingDependencies = [];
    }
    BatchDateFieldOption.prototype.matchesComponent = function (component) {
        return this.component.id === component.id && this.component.type === component.type;
    };
    BatchDateFieldOption.prototype.canBeAdded = function (components) {
        var hasExistingBatchDateComponent = components
            .filter(hooks_1.isDateComponent)
            .some(function (c) { return c.allowEntityBatchCreation; });
        return !hasExistingBatchDateComponent;
    };
    BatchDateFieldOption.prototype.updateMissingDependencies = function (components) {
        this.missingDependencies = this.componentDependencies.filter(function (dep) { return dep.canBeAdded(components); });
    };
    return BatchDateFieldOption;
}());
exports.BatchDateFieldOption = BatchDateFieldOption;
