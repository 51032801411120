"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isFieldRecordModified = isFieldRecordModified;
exports.isFieldModified = isFieldModified;
exports.getModifiedFields = getModifiedFields;
var lodash_1 = require("lodash");
function isFieldRecordModified(originalFields, updatedFields) {
    // If the number of fields is different, they are dirty
    var originalFieldKeys = Object.keys(originalFields);
    var updatedFieldKeys = Object.keys(updatedFields);
    if (originalFieldKeys.length !== updatedFieldKeys.length) {
        return true;
    }
    return originalFieldKeys.some(function (componentId) {
        var originalField = originalFields[componentId];
        var updatedField = updatedFields[componentId];
        return isFieldModified(originalField, updatedField);
    });
}
function isFieldModified(originalField, updatedField) {
    var originalIsEmpty = (0, lodash_1.isNil)(originalField) || originalField.isEmpty;
    var updatedIsEmpty = (0, lodash_1.isNil)(updatedField) || updatedField.isEmpty;
    // If both are null or empty, they are not dirty
    if (originalIsEmpty && updatedIsEmpty) {
        return false;
    }
    // If one is null or empty, but the other is not, they are dirty.
    // (This requires the above 'both' check to run first.)
    if (originalIsEmpty || updatedIsEmpty) {
        return true;
    }
    // If they are not equal, they are dirty
    return !originalField.isEqual(updatedField);
}
function getModifiedFields(originalFields, updatedFields) {
    if ((0, lodash_1.isNil)(originalFields) || (0, lodash_1.isNil)(updatedFields)) {
        return null;
    }
    return Object.keys(originalFields).reduce(function (acc, componentId) {
        var originalField = originalFields[componentId];
        var updatedField = updatedFields[componentId];
        var isModified = isFieldModified(originalField, updatedField);
        if (isModified) {
            acc[componentId] = updatedField;
        }
        return acc;
    }, {});
}
