"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBatchRule = useBatchRule;
var lodash_1 = require("lodash");
var utils_1 = require("../utils");
var lodash_2 = require("lodash");
var types_1 = require("@shared/scenes/entity/components/fields/DateEditField/types");
var react_1 = require("react");
var rrule_1 = require("rrule");
var useBatchPresets_1 = require("./useBatchPresets");
var hooks_1 = require("@shared/util/hooks");
function useBatchRule(_a) {
    var _b;
    var rruleOptions = _a.rruleOptions, setRruleOptions = _a.setRruleOptions, field = _a.field, isBatchRuleEnabled = _a.isBatchRuleEnabled;
    var isMobile = (0, hooks_1.useIsMobile)();
    var _c = (0, react_1.useState)((_b = rruleOptions === null || rruleOptions === void 0 ? void 0 : rruleOptions.count) !== null && _b !== void 0 ? _b : utils_1.MAX_COUNT), repeatCount = _c[0], setRepeatCount = _c[1];
    var _d = (0, react_1.useState)(), repeatUntil = _d[0], setRepeatUntil = _d[1];
    var _e = (0, react_1.useState)(types_1.RepeatEndType.COUNT), repeatEndType = _e[0], setRepeatEndType = _e[1];
    var prevRepeatEndType = (0, react_1.useRef)(repeatEndType);
    var repeatPresets = (0, useBatchPresets_1.useBatchPresets)({
        skip: !isBatchRuleEnabled,
        date: field === null || field === void 0 ? void 0 : field.date,
        count: repeatCount,
        until: repeatUntil,
    });
    var filteredRepeatPresets = repeatPresets.filter(function (p) { var _a; return isMobile || p.freq === ((_a = rruleOptions === null || rruleOptions === void 0 ? void 0 : rruleOptions.freq) !== null && _a !== void 0 ? _a : rrule_1.RRule.WEEKLY); });
    var rruleOptionsString = !(0, lodash_2.isNil)(rruleOptions) ? new rrule_1.RRule(rruleOptions).toString() : '';
    var _f = (0, react_1.useState)(!(0, lodash_1.isEmpty)(rruleOptionsString)
        ? filteredRepeatPresets.findIndex(function (p) { return new rrule_1.RRule(p).toString() === rruleOptionsString; })
        : 0), presetIndexSelected = _f[0], setPresetIndexSelected = _f[1];
    var allDates = (0, react_1.useMemo)(function () { return (!(0, lodash_2.isNil)(rruleOptions === null || rruleOptions === void 0 ? void 0 : rruleOptions.freq) ? (0, utils_1.getAllDates)(rruleOptions) : []); }, [rruleOptions]);
    /*
     When the rule updates, ensure the until date is in range.
     Set it to closest available date otherwise.
     */
    (0, react_1.useEffect)(function () {
        if ((0, lodash_2.isNil)(rruleOptions === null || rruleOptions === void 0 ? void 0 : rruleOptions.until)) {
            return;
        }
        var allDatesByCount = (0, utils_1.getAllDates)(__assign(__assign({}, rruleOptions), { until: undefined, count: 60 }));
        if (rruleOptions.until.getTime() !== allDatesByCount[allDatesByCount.length - 1].getTime()) {
            var closestDate_1;
            var closestDiff_1;
            allDatesByCount.forEach(function (date) {
                if (!(0, lodash_2.isNil)(rruleOptions.until) && rruleOptions.until.getTime() >= date.getTime()) {
                    var diff = Math.abs(rruleOptions.until.getTime() - date.getTime());
                    if ((!(0, lodash_2.isNil)(closestDiff_1) && diff < closestDiff_1) || (0, lodash_2.isNil)(closestDiff_1)) {
                        closestDiff_1 = diff;
                        closestDate_1 = date;
                    }
                }
            });
            if (!(0, lodash_2.isNil)(rruleOptions.until) &&
                !(0, lodash_2.isNil)(closestDate_1) &&
                closestDate_1.getTime() !== rruleOptions.until.getTime()) {
                setRepeatUntil(closestDate_1);
            }
        }
    }, [rruleOptions]);
    /*
      When the repeat end type changes, we need to update the repeat count and until date.
    */
    (0, react_1.useEffect)(function () {
        if ((0, lodash_1.isEmpty)(allDates) || prevRepeatEndType.current === repeatEndType) {
            return;
        }
        if (repeatEndType === types_1.RepeatEndType.END_DATE) {
            setRepeatCount(undefined);
            setRepeatUntil(allDates[allDates.length - 1]);
        }
        else if (repeatEndType === types_1.RepeatEndType.COUNT) {
            setRepeatUntil(undefined);
            setRepeatCount(utils_1.MAX_COUNT);
        }
        prevRepeatEndType.current = repeatEndType;
    }, [repeatEndType, allDates]);
    /*
      When the repeat count changes, we need to update the rule and until date.
    */
    (0, react_1.useEffect)(function () {
        if (!isBatchRuleEnabled) {
            return;
        }
        setRruleOptions(function (prev) {
            if (!(0, lodash_2.isNil)(prev) &&
                !(0, lodash_2.isNil)(repeatCount) &&
                (prev === null || prev === void 0 ? void 0 : prev.count) !== repeatCount &&
                repeatCount >= utils_1.MIN_COUNT &&
                repeatCount <= utils_1.MAX_COUNT) {
                setRepeatUntil(undefined);
                return __assign(__assign({}, prev), { until: undefined, count: repeatCount });
            }
            return prev;
        });
    }, [isBatchRuleEnabled, repeatCount, setRruleOptions]);
    /*
      When the repeat until date changes, we need to update the rule and count.
    */
    (0, react_1.useEffect)(function () {
        if (!isBatchRuleEnabled) {
            return;
        }
        setRruleOptions(function (prev) {
            if (!(0, lodash_2.isNil)(prev) && !(0, lodash_2.isNil)(repeatUntil) && (prev === null || prev === void 0 ? void 0 : prev.until) !== repeatUntil) {
                setRepeatCount(undefined);
                return __assign(__assign({}, prev), { until: repeatUntil, count: undefined });
            }
            return prev;
        });
    }, [isBatchRuleEnabled, repeatUntil, setRruleOptions]);
    /*
      When the field date changes, we need to update the dtstart.
    */
    (0, react_1.useEffect)(function () {
        if ((0, lodash_2.isNil)(field) || (0, lodash_2.isNil)(field === null || field === void 0 ? void 0 : field.date) || !isBatchRuleEnabled) {
            return;
        }
        setRruleOptions(function (prev) {
            var dateObj = new Date(field.date);
            var dstartFormatted = (0, utils_1.getUTCDate)(dateObj);
            if (!(0, lodash_2.isNil)(prev) && !(0, lodash_2.isNil)(dstartFormatted) && prev.dtstart !== dstartFormatted) {
                return __assign(__assign({}, prev), { dtstart: dstartFormatted });
            }
            return prev;
        });
    }, [isBatchRuleEnabled, field, setRruleOptions]);
    /*
      When the preset index changes, we need to update the rule.
    */
    (0, react_1.useEffect)(function () {
        var _a;
        if (!isBatchRuleEnabled ||
            (0, lodash_2.isNil)(rruleOptions === null || rruleOptions === void 0 ? void 0 : rruleOptions.dtstart) ||
            (0, lodash_2.isNil)((_a = filteredRepeatPresets[0]) === null || _a === void 0 ? void 0 : _a.dtstart) ||
            (rruleOptions === null || rruleOptions === void 0 ? void 0 : rruleOptions.dtstart.toISOString()) === filteredRepeatPresets[0].dtstart.toISOString() ||
            presetIndexSelected === -1) {
            return;
        }
        setRruleOptions(filteredRepeatPresets[presetIndexSelected]);
    }, [
        rruleOptions === null || rruleOptions === void 0 ? void 0 : rruleOptions.dtstart,
        setRruleOptions,
        filteredRepeatPresets,
        presetIndexSelected,
        isBatchRuleEnabled,
    ]);
    var isCustomRepeatSelected = presetIndexSelected === -1;
    return {
        repeatPresets: repeatPresets,
        filteredRepeatPresets: filteredRepeatPresets,
        setRepeatCount: setRepeatCount,
        repeatCount: repeatCount,
        presetIndexSelected: presetIndexSelected,
        setPresetIndexSelected: setPresetIndexSelected,
        isCustomRepeatSelected: isCustomRepeatSelected,
        repeatUntil: repeatUntil,
        setRepeatUntil: setRepeatUntil,
        repeatEndType: repeatEndType,
        setRepeatEndType: setRepeatEndType,
    };
}
