"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelatedEntityField = void 0;
var lodash_1 = require("lodash");
var RelatedEntityField = /** @class */ (function () {
    function RelatedEntityField(jsonBlob, entityRefs) {
        this.fieldType = 'RelatedCardField';
        this.items = jsonBlob;
        var itemIds = this.items.map(function (item) { return item.id; });
        this.relatedEntities = entityRefs.filter(function (entity) { return itemIds.includes(entity.id); });
    }
    RelatedEntityField.prototype.validate = function () {
        return null;
    };
    RelatedEntityField.prototype.isEqual = function (field) {
        return field instanceof RelatedEntityField && (0, lodash_1.isEqual)(this.items, field.items);
    };
    RelatedEntityField.prototype.toFieldJson = function () {
        return this.items;
    };
    Object.defineProperty(RelatedEntityField.prototype, "isEmpty", {
        get: function () {
            return (0, lodash_1.isEmpty)(this.items);
        },
        enumerable: false,
        configurable: true
    });
    return RelatedEntityField;
}());
exports.RelatedEntityField = RelatedEntityField;
