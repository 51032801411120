"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TodoEditField = TodoEditField;
var components_1 = require("@shared/components");
var animation_1 = require("@shared/components/animation");
var hooks_1 = require("@shared/components/blocks/lib/inputTodo/hooks");
var TodoField_1 = require("@shared/data/entity/fields/TodoField");
var useWorkspaceDetails_1 = require("@shared/scenes/workflows/hooks/useWorkspaceDetails");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var hooks_2 = require("@shared/util/permissions/hooks");
var uuid = __importStar(require("@shared/util/uuid"));
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var react_redux_1 = require("react-redux");
var EditFieldContainer_1 = require("./EditFieldContainer");
function TodoEditField(_a) {
    var field = _a.field, component = _a.component, workflowTemplateId = _a.workflowTemplateId, updateField = _a.updateField;
    var _b = (0, react_1.useState)(function () {
        var _a;
        var existingTodos = (_a = field === null || field === void 0 ? void 0 : field.jsonBlob) !== null && _a !== void 0 ? _a : [];
        return __spreadArray(__spreadArray([], existingTodos, true), [createEmptyTodoItem()], false);
    }), editableTodoItems = _b[0], setEditableTodoItems = _b[1];
    (0, react_1.useEffect)(function () {
        // since we autopopulate the last item, we need to remove it if it's empty
        var updatedTodos = editableTodoItems.filter(removeLastItemIfEmpty);
        updateField(component.id, new TodoField_1.TodoField(updatedTodos));
    }, [editableTodoItems, field, component.id, updateField]);
    var addedTodoItems = editableTodoItems.slice(0, editableTodoItems.length - 1);
    var workInProgressTodoItem = editableTodoItems[editableTodoItems.length - 1];
    var label = formatComponentLabel(component, editableTodoItems);
    var handleAdd = (0, react_1.useCallback)(function () {
        setEditableTodoItems(function (prev) { return __spreadArray(__spreadArray([], prev, true), [createEmptyTodoItem()], false); });
    }, []);
    var handleAddMultiple = (0, react_1.useCallback)(function (newTodos) {
        setEditableTodoItems(function (prev) { return __spreadArray(__spreadArray(__spreadArray([], prev.filter(removeLastItemIfEmpty), true), newTodos, true), [
            createEmptyTodoItem(),
        ], false); });
    }, []);
    var handleUpdate = (0, react_1.useCallback)(function (updatedTodo) {
        setEditableTodoItems(function (prev) {
            return prev.map(function (todo) {
                return todo.id === updatedTodo.id ? updatedTodo : todo;
            });
        });
    }, []);
    var handleRemove = (0, react_1.useCallback)(function (id) {
        setEditableTodoItems(function (prev) { return prev.filter(function (todo) { return todo.id !== id; }); });
    }, []);
    var hasItems = !(0, lodash_1.isEmpty)(field === null || field === void 0 ? void 0 : field.jsonBlob);
    var isReadOnly = !!component.readonly;
    if (isReadOnly && !hasItems) {
        return null;
    }
    return (react_1.default.createElement(EditFieldContainer_1.EditFieldContainer, { component: component, label: label, indentLabel: true, showLabel: hasItems, showIcon: false },
        react_1.default.createElement(components_1.MBView, { style: { marginTop: -(0, components_1.grid)(0.75) } },
            addedTodoItems.map(function (todoItem) { return (react_1.default.createElement(TodoItemRow, { key: todoItem.id, component: component, todoItem: todoItem, workflowTemplateId: workflowTemplateId, onUpdate: handleUpdate, onRemove: handleRemove, isReadOnly: isReadOnly })); }),
            !isReadOnly && (react_1.default.createElement(TodoAddRow, { component: component, todoItem: workInProgressTodoItem, onAdd: handleAdd, onAddMultiple: handleAddMultiple, onUpdate: handleUpdate })))));
}
function removeLastItemIfEmpty(todoItem, index, todos) {
    var isLastItem = index === todos.length - 1;
    var hasEmptySummary = (0, lodash_1.isEmpty)(todoItem.summary);
    if (isLastItem && hasEmptySummary) {
        return false;
    }
    return true;
}
// This row always works with an existing todo item, which is the last in the array, so that we can "add" this todo item
// to the list in the event that the user saves without hitting the add button. The "add" row is just a visual effect:
// this item exists before the user hits the button.
function TodoAddRow(_a) {
    var component = _a.component, todoItem = _a.todoItem, onAdd = _a.onAdd, onAddMultiple = _a.onAddMultiple, onUpdate = _a.onUpdate;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var isDesktop = (0, react_redux_1.useSelector)(function (state) { return !!state.appState.isDesktop; });
    var scrollTextInputIntoView = (0, hooks_1.useScrollTextInputIntoView)();
    var debouncedDcrollTextInputIntoView = (0, hooks_1.useDebouncedScrollTextInputIntoView)();
    var _b = (0, react_1.useState)(todoItem.summary), value = _b[0], setValue = _b[1];
    var handleAdd = function () {
        var trimmedValue = value.trim();
        if (!(0, lodash_1.isEmpty)(trimmedValue)) {
            setValue('');
            onAdd();
            // hack to clear the text input on mobile
            if (!isDesktop) {
                setTimeout(function () {
                    setValue('');
                }, 250);
            }
        }
    };
    var handleChangeText = function (text) {
        setValue(text);
        onUpdate(__assign(__assign({}, todoItem), { summary: text.trim() }));
    };
    var handlePaste = function (pastedValue) {
        var pastedLines = pastedValue
            .split('\n')
            .map(function (line) { return line.trim(); })
            .filter(function (line) { return !!line; });
        if (pastedLines.length < 2) {
            return true;
        }
        // join the current value with the first line of the pasted value
        var firstLine = pastedLines[0], restOfLines = pastedLines.slice(1);
        var inclusiveFirstLine = "".concat(value, " ").concat(firstLine).trim();
        var multipleTodos = __spreadArray([inclusiveFirstLine], restOfLines, true).map(createTodo);
        onAddMultiple(multipleTodos);
        setValue('');
        scrollTextInputIntoView();
        return false;
    };
    var handleKeyPress = function (e) {
        if (e.nativeEvent.key === 'Enter') {
            handleAdd();
        }
    };
    var onClear = (0, react_1.useCallback)(function () {
        setValue('');
        onUpdate(__assign(__assign({}, todoItem), { summary: '' }));
    }, [todoItem, onUpdate]);
    return (react_1.default.createElement(components_1.MBView, { row: true },
        react_1.default.createElement(components_1.MBTouchableOpacity, { disabled: !value, onPress: handleAdd },
            react_1.default.createElement(components_1.MBIconV2, { name: "plus-circle", color: colors.accent })),
        react_1.default.createElement(components_1.MBView, { paddingRight: 2 }),
        isDesktop && (react_1.default.createElement(components_1.MBAutosizeTextInput, { blurOnSubmit: false, value: value, returnKeyType: "done", padding: "none", placeholder: component.placeholder || (0, i18n_1.i18n)('card.blocks.todoInput.addPlaceholder'), style: { flex: 1, alignSelf: 'center', marginTop: 4, marginBottom: 4 }, color: colors.foregroundActive, onFocus: scrollTextInputIntoView, onChangeText: handleChangeText, onPaste: handlePaste, onSubmitEditing: handleAdd })),
        !isDesktop && (react_1.default.createElement(components_1.MBView, { style: { flex: 1, alignSelf: 'center' } },
            react_1.default.createElement(react_native_1.TextInput, { blurOnSubmit: false, textAlignVertical: 'center', style: [components_1.MBTypographyStyles.body1, styles.nativeInput], value: value, returnKeyType: "done", placeholder: component.placeholder || (0, i18n_1.i18n)('card.blocks.todoInput.addPlaceholder'), placeholderTextColor: colors.foregroundInactive, multiline: true, onFocus: debouncedDcrollTextInputIntoView, onContentSizeChange: debouncedDcrollTextInputIntoView, onChangeText: handleChangeText, onKeyPress: handleKeyPress, onSubmitEditing: handleAdd }))),
        !(0, lodash_1.isEmpty)(value) && react_1.default.createElement(components_1.MBClearButton, { onPress: onClear })));
}
function TodoItemRow(_a) {
    var _this = this;
    var _b;
    var component = _a.component, todoItem = _a.todoItem, workflowTemplateId = _a.workflowTemplateId, onRemove = _a.onRemove, onUpdate = _a.onUpdate, isReadOnly = _a.isReadOnly;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var isDesktop = (0, react_redux_1.useSelector)(function (state) { return state.appState.isDesktop; });
    var workspaceDetails = (0, useWorkspaceDetails_1.useWorkspaceDetails)();
    var scrollTextInputIntoView = (0, hooks_1.useScrollTextInputIntoView)();
    var debouncedDcrollTextInputIntoView = (0, hooks_1.useDebouncedScrollTextInputIntoView)();
    var updatePermission = (0, hooks_2.usePermission)({
        action: 'update',
        subject: 'Card',
        channelId: (_b = workspaceDetails.get(workflowTemplateId)) === null || _b === void 0 ? void 0 : _b.id,
    });
    var isComplete = todoItem.status === 'COMPLETED';
    var handleChangeText = function (summary) { return onUpdate(__assign(__assign({}, todoItem), { summary: summary })); };
    var handleToggleStatus = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            onUpdate(__assign(__assign({}, todoItem), { status: todoItem.status === 'COMPLETED' ? 'NEEDS-ACTION' : 'COMPLETED' }));
            return [2 /*return*/];
        });
    }); };
    var handleRemove = function () { return onRemove(todoItem.id); };
    return (react_1.default.createElement(components_1.MBView, { row: true },
        react_1.default.createElement(components_1.MBTouchableOpacity, { disabled: !updatePermission.enabled, onPress: handleToggleStatus },
            react_1.default.createElement(components_1.MBView, { paddingRight: 2, style: { opacity: 0.7 } }, isComplete ? (react_1.default.createElement(animation_1.MBAnimationBounceIn, null,
                react_1.default.createElement(components_1.MBIconV2, { name: "task-filled", color: colors.accent }))) : (react_1.default.createElement(components_1.MBIconV2, { name: "unfilled-circle", color: colors.accentInactive })))),
        isDesktop && (react_1.default.createElement(components_1.MBAutosizeTextInput, { value: todoItem.summary, returnKeyType: "done", padding: "none", blurOnSubmit: true, placeholder: component.placeholder || (0, i18n_1.i18n)('card.blocks.todoInput.addPlaceholder'), color: isComplete ? colors.foregroundInactive : colors.foregroundActive, onFocus: scrollTextInputIntoView, onChangeText: handleChangeText, onSubmitEditing: react_native_1.Keyboard === null || react_native_1.Keyboard === void 0 ? void 0 : react_native_1.Keyboard.dismiss, disabled: isReadOnly, style: {
                flex: 1,
                alignSelf: 'center',
                marginTop: 0,
                marginBottom: 0,
                textDecorationLine: isComplete ? 'line-through' : 'none',
            } })),
        !isDesktop && (react_1.default.createElement(components_1.MBView, { style: { flex: 1, alignSelf: 'center' } },
            react_1.default.createElement(react_native_1.TextInput, { value: todoItem.summary, returnKeyType: "done", blurOnSubmit: true, textAlignVertical: 'center', placeholder: component.placeholder || (0, i18n_1.i18n)('card.blocks.todoInput.addPlaceholder'), placeholderTextColor: colors.foregroundInactive, onFocus: debouncedDcrollTextInputIntoView, onContentSizeChange: debouncedDcrollTextInputIntoView, onChangeText: handleChangeText, onSubmitEditing: react_native_1.Keyboard === null || react_native_1.Keyboard === void 0 ? void 0 : react_native_1.Keyboard.dismiss, multiline: true, editable: !isReadOnly, style: [
                    components_1.MBTypographyStyles.body1,
                    styles.nativeInput,
                    {
                        textDecorationLine: isComplete ? 'line-through' : 'none',
                        color: isComplete ? colors.foregroundInactive : colors.foregroundActive,
                    },
                ] }))),
        !isReadOnly && react_1.default.createElement(components_1.MBClearButton, { onPress: handleRemove })));
}
function createEmptyTodoItem() {
    return {
        summary: '',
        status: 'NEEDS-ACTION',
        id: uuid.v4(),
    };
}
function createTodo(summary) {
    return {
        summary: summary,
        status: 'NEEDS-ACTION',
        id: uuid.v4(),
    };
}
function formatComponentLabel(component, todos) {
    if (!component.label) {
        return undefined;
    }
    var totalItems = todos.length - 1;
    var label = component.label.toUpperCase();
    if (totalItems === 0) {
        return label;
    }
    var completedItems = todos.filter(function (todo) { return todo.status === 'COMPLETED'; }).length;
    return "".concat(label, " (").concat(completedItems, "/").concat(totalItems, ")");
}
var styles = react_native_1.StyleSheet.create({
    nativeInput: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        maxHeight: 100,
        marginTop: (0, components_1.grid)(0.75),
        marginBottom: (0, components_1.grid)(0.75),
    },
});
