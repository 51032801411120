"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useEditCardEntity = useEditCardEntity;
var lodash_1 = require("lodash");
var react_1 = require("react");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var useEditFields_1 = require("./useEditFields");
var useSaveCardEntity_1 = require("./useSaveCardEntity");
var useVisibleComponents_1 = require("../../util/useVisibleComponents");
function useEditCardEntity(entityId, workflowTemplateId, viewTemplateId) {
    var workflowTemplate = (0, hooks_1.useWorkflowTemplate)(workflowTemplateId).workflowTemplate;
    var viewTemplate = (0, hooks_1.useViewTemplate)(workflowTemplateId, viewTemplateId).viewTemplate;
    var _a = (0, react_1.useState)('loading'), status = _a[0], setStatus = _a[1];
    var fields = (0, useEditFields_1.useEditFields)(entityId, workflowTemplate);
    var saveEntity = (0, useSaveCardEntity_1.useSaveEntityFields)(entityId);
    var visibleComponents = (0, useVisibleComponents_1.useVisibleComponents)(workflowTemplate, viewTemplate);
    (0, react_1.useEffect)(function () {
        if (fields.loading || saveEntity.loading) {
            setStatus('loading');
        }
        else if (!(0, lodash_1.isEmpty)(fields.error) && !(0, lodash_1.isEmpty)(saveEntity.errors)) {
            setStatus('error');
        }
        else if (status !== 'success') {
            setStatus('input');
        }
    }, [fields.error, fields.loading, saveEntity.errors, saveEntity.loading, status]);
    var save = function () {
        if (!saveEntity.loading && !(0, lodash_1.isNil)(fields.fields)) {
            saveEntity.saveEntity(fields.fields).then(function (_fulfilled) {
                setStatus('success');
            }, function (_error) {
                // parse remote validation
                setStatus('error');
            });
        }
    };
    return {
        components: visibleComponents,
        fields: fields.fields,
        errors: saveEntity.errors,
        updateField: fields.updateField,
        save: save,
        isLoading: status === 'loading',
        status: status,
        isDirty: fields.isDirty,
    };
}
