"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelatedQuantityEditField = RelatedQuantityEditField;
var react_1 = __importStar(require("react"));
var RelatedEntityField_1 = require("@shared/data/entity/fields/RelatedEntityField");
var components_1 = require("@shared/components");
var react_native_1 = require("react-native");
var lodash_1 = require("lodash");
var EditFieldContainer_1 = require("../EditFieldContainer");
var hooks_1 = require("@shared/util/hooks");
var RelatedEntityPicker_1 = require("./RelatedEntityPicker");
var RelatedQuantityList_1 = require("./RelatedQuantityList");
var i18n_1 = require("@shared/util/i18n");
function RelatedQuantityEditField(_a) {
    var component = _a.component, field = _a.field, updateField = _a.updateField, fields = _a.fields;
    var containerRef = (0, react_1.useRef)(null);
    var _b = (0, hooks_1.useBoolean)(false), isPickerOpen = _b[0], openPicker = _b[1], closePicker = _b[2];
    var items = (0, react_1.useMemo)(function () { var _a; return (_a = field === null || field === void 0 ? void 0 : field.items) !== null && _a !== void 0 ? _a : []; }, [field]);
    var entities = (0, react_1.useMemo)(function () { var _a; return (_a = field === null || field === void 0 ? void 0 : field.relatedEntities) !== null && _a !== void 0 ? _a : []; }, [field]);
    var onChange = (0, react_1.useCallback)(function (updatedEntities) {
        var updatedItems = updatedEntities.map(function (entity) {
            var match = items.find(function (item) { return item.id === entity.id; });
            return match !== null && match !== void 0 ? match : { id: entity.id, quantity: 1 };
        });
        updateField(component.id, new RelatedEntityField_1.RelatedEntityField(updatedItems, updatedEntities));
    }, [component.id, updateField, items]);
    var removeItem = (0, react_1.useCallback)(function (id) {
        var updatedItems = items.filter(function (item) { return item.id !== id; });
        updateField(component.id, new RelatedEntityField_1.RelatedEntityField(updatedItems, entities));
    }, [component.id, updateField, items, entities]);
    var updateItemQuantity = (0, react_1.useCallback)(function (id, quantity) {
        var updatedItems = items.map(function (item) {
            return item.id === id ? __assign(__assign({}, item), { quantity: quantity }) : item;
        });
        updateField(component.id, new RelatedEntityField_1.RelatedEntityField(updatedItems, entities));
    }, [component.id, updateField, items, entities]);
    var hasEntities = !(0, lodash_1.isEmpty)(entities);
    var label = getLabelWithCountAndType(component, items);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(react_native_1.TouchableOpacity, { ref: containerRef }),
        react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: hasEntities ? undefined : openPicker },
            react_1.default.createElement(EditFieldContainer_1.EditFieldContainer, { component: component, label: label, showLabel: hasEntities, showPlaceholder: !hasEntities }, hasEntities && (react_1.default.createElement(RelatedQuantityList_1.RelatedQuantityList, { component: component, items: items, entities: entities, openPicker: openPicker, updateItemQuantity: updateItemQuantity, removeItem: removeItem })))),
        isPickerOpen && (react_1.default.createElement(RelatedEntityPicker_1.RelatedEntityPicker, { entities: entities, onChange: onChange, onClose: closePicker, containerRef: containerRef, component: component, fields: fields }))));
}
function getLabelWithCountAndType(component, items) {
    var _a;
    var count = items.length;
    var totalQuantity = (0, lodash_1.sum)(items.map(function (item) { var _a; return (_a = item.quantity) !== null && _a !== void 0 ? _a : 0; }));
    var componentLabel = (_a = component.label) !== null && _a !== void 0 ? _a : '';
    return (0, i18n_1.i18n)('card.blocks.cardsInput.quantity.label', {
        label: componentLabel,
        totalCount: totalQuantity,
        count: count,
    });
}
