"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Config = void 0;
// @ts-strict-ignore
var react_native_1 = require("react-native");
var default_1 = require("./default");
var staging_1 = require("./staging");
var productionConfig = __assign(__assign({}, default_1.Config), { APP_URL: 'https://app.coastapp.com', API_URL: 'https://api.coastapp.com', AUTH_URL: 'https://auth.coastapp.com', BUILDER_APP_URL: 'https://app.coastapp.com/builder', WEBSOCKET_URL: 'wss://api.coastapp.com', IMGIX_URL: 'https://coast-production.imgix.net', SEGMENT_API_KEY: 'cTXVkMGX6kGDDjl7mHTGbHfIjQ7obkFh', INTERCOM_APP_ID: 'ph3dv3eh', ONESIGNAL_APP_ID: '2e31da7c-d7a1-4f1d-be99-a6af5615d1d2', BRANCH_API_KEY: 'key_live_jasIykxAEeCslTZfYrsyPlnmCrmjZEn8', FACEBOOK_PIXEL_ID: '146325875975516', STRIPE_API_KEY: 'pk_live_8HZchISmXjHNDHxUyoixgOav00KoyKEbzL', STRIPE_PLANS: {
        starter: {
            monthly: { price: 5, priceId: 'price_1HrD9THcHJNZqWD3LjqW7Xsn' },
            yearly: { price: 4, priceId: 'price_1HrD9THcHJNZqWD3RFKxagb8' },
        },
        plus: {
            monthly: { price: 10, priceId: 'price_1HrD9ZHcHJNZqWD3S1pUucke' },
            yearly: { price: 8, priceId: 'price_1HrD9ZHcHJNZqWD3JnEcvcgd' },
        },
    }, LAUNCH_DARKLY_CLIENT_KEY: '657c9003a87b5f0fdbd00a99', LAUNCH_DARKLY_MOBILE_KEY: 'mob-e336ed23-f057-4cd2-a1b2-2b88e8536222' });
exports.Config = (function () {
    if (react_native_1.Platform.OS === 'web') {
        // On web, the NODE_ENV will always be "production" when the
        // static JS bundle is build in the Docker container. Therefore
        // the production config will always be used, even in staging or
        // personal environments.
        var isStaging = window.location.host.match(/^(staging|staging-forms|staging-shared)\.coastapp\.com$/);
        var isProduction = window.location.host.match(/^(app|beta|forms|shared)\.coastapp\.com$/);
        var isCustomDeployment = window.location.origin.match(/now\.sh$/) || window.location.origin.match(/vercel\.app$/);
        if (isProduction) {
            return productionConfig;
        }
        if (isStaging) {
            return staging_1.Config;
        }
        if (isCustomDeployment) {
            var appUrl = window.location.host;
            var apiUrl = 'api.staging.coastapp.com';
            return __assign(__assign({}, staging_1.Config), { APP_URL: "https://".concat(appUrl), API_URL: "https://".concat(apiUrl), WEBSOCKET_URL: "wss://".concat(apiUrl) });
        }
        // On web, we use URLs relative to the current host. This makes
        // it easy to eg deploy to personal environments (fant.dev.coastapp.com)
        // without having to manually configure that environment in code.
        var _a = window.location, origin_1 = _a.origin, host = _a.host, hostname = _a.hostname;
        var APP_URL = origin_1;
        var API_URL = origin_1;
        var WEBSOCKET_URL = hostname === 'localhost' ? "ws://".concat(host) : "wss://".concat(host);
        return __assign(__assign({}, staging_1.Config), { APP_URL: APP_URL, API_URL: API_URL, WEBSOCKET_URL: WEBSOCKET_URL });
    }
    return productionConfig;
})();
