"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseNumber = parseNumber;
exports.formatNumber = formatNumber;
function parseNumber(value, numberMode) {
    var mode = numberMode.toLowerCase();
    var valueWithoutCommas = value.replace(/[,]/g, '');
    if (mode === 'currency') {
        return Number(valueWithoutCommas.replace(/^[$]/, ''));
    }
    if (mode === 'percent') {
        var match = valueWithoutCommas.match(/^(.*)[%]$/);
        if (!match) {
            return Number(valueWithoutCommas);
        }
        // 10% => 0.1
        return Number(match[1]) / 100;
    }
    return Number(valueWithoutCommas);
}
function formatNumber(value, numberMode, showZeroDigitsIfRound) {
    switch (numberMode.toLowerCase()) {
        case 'float':
            return new Intl.NumberFormat().format(value);
        case 'percent':
            return new Intl.NumberFormat(undefined, {
                style: 'percent',
                maximumFractionDigits: 3,
            }).format(value);
        case 'currency':
            var digits = showZeroDigitsIfRound && Math.round(value / 100) === value / 100 ? 0 : 2;
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: digits,
                maximumFractionDigits: digits,
            }).format(value / 100);
        default:
            console.warn('Unknown number mode', numberMode);
            return value.toString();
    }
}
