"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCardDetailsOptionsButton = MBCardDetailsOptionsButton;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var styles_1 = require("@shared/styles");
var components_1 = require("@shared/components");
var useNavigator_1 = require("@shared/util/navigation/useNavigator");
var useOpenCardOptionsMenu_1 = require("../useOpenCardOptionsMenu");
var channel_1 = require("@shared/components/blocks/contexts/channel");
var ShareSheet_1 = require("./ShareSheet");
var CardExport_1 = require("./CardExport");
var hooks_1 = require("../../hooks");
function MBCardDetailsOptionsButton(_a) {
    var cardId = _a.cardId, channelSlug = _a.channelSlug, props = __rest(_a, ["cardId", "channelSlug"]);
    var card = (0, hooks_1.useCard)(cardId).card;
    return (react_1.default.createElement(channel_1.MBCardChannelProvider, { channelSlug: channelSlug }, card && react_1.default.createElement(CardDetailsOptionsButton, __assign({ card: card }, props))));
}
function CardDetailsOptionsButton(_a) {
    var card = _a.card, style = _a.style, showEdit = _a.showEdit;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var goBack = (0, useNavigator_1.useNavigator)().goBack;
    var _b = (0, react_1.useState)(false), showShareSheet = _b[0], setShowShareSheet = _b[1];
    var _c = (0, react_1.useState)(false), showDownloadSheet = _c[0], setShowDownloadSheet = _c[1];
    var containerRef = (0, react_1.useRef)();
    var onPress = (0, useOpenCardOptionsMenu_1.useOpenCardOptionsMenu)(card, {
        onRemoved: goBack,
        showEdit: showEdit,
        onShare: function () { return setShowShareSheet(true); },
        onDownload: function () { return setShowDownloadSheet(true); },
    });
    return (react_1.default.createElement(react_native_1.View, { ref: containerRef },
        react_1.default.createElement(components_1.MBTouchableOpacity, { style: style, onPress: onPress },
            react_1.default.createElement(components_1.MBIconV2, { color: colors.foregroundActive, name: "dots" })),
        showShareSheet && (react_1.default.createElement(ShareSheet_1.ShareSheet, { containerRef: containerRef, card: card, onCloseSheet: function () { return setShowShareSheet(false); } })),
        showDownloadSheet && (react_1.default.createElement(CardExport_1.CardExport, { containerRef: containerRef, card: card, onCloseSheet: function () { return setShowDownloadSheet(false); } }))));
}
