"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isComponentHidden = isComponentHidden;
exports.isComponentReadOnlyInView = isComponentReadOnlyInView;
exports.getComponentViewOptions = getComponentViewOptions;
exports.isComponentReadOnly = isComponentReadOnly;
var lodash_1 = require("lodash");
function isComponentHidden(viewTemplate, viewOptions, component) {
    var _a, _b;
    var overrideValue = (_b = (_a = viewOptions === null || viewOptions === void 0 ? void 0 : viewOptions.overrides) === null || _a === void 0 ? void 0 : _a.isHidden) !== null && _b !== void 0 ? _b : viewOptions === null || viewOptions === void 0 ? void 0 : viewOptions.isHidden;
    if (!(0, lodash_1.isNil)(overrideValue)) {
        return overrideValue;
    }
    return (component === null || component === void 0 ? void 0 : component.isHidden) === true || (viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.defaultHiddenComponents) === true;
}
function isComponentReadOnlyInView(component, viewTemplate) {
    var _a;
    var viewOptions = getComponentViewOptions(component, viewTemplate);
    return isComponentReadOnly(component, viewOptions === null || viewOptions === void 0 ? void 0 : viewOptions.overrides, (_a = viewTemplate.defaultReadonlyComponents) !== null && _a !== void 0 ? _a : undefined);
}
function getComponentViewOptions(component, viewTemplate) {
    var _a;
    return (_a = viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.componentsViewOptions) === null || _a === void 0 ? void 0 : _a.find(function (option) { return component.id === option.componentId; });
}
function isComponentReadOnly(component, overrides, templateDefaultReadonly) {
    var overrideReadonly = overrides === null || overrides === void 0 ? void 0 : overrides.readonly;
    if (!(0, lodash_1.isNil)(overrideReadonly)) {
        return overrideReadonly;
    }
    return ((!(0, lodash_1.isNil)(component.readonly) && component.readonly) ||
        (!(0, lodash_1.isNil)(templateDefaultReadonly) && templateDefaultReadonly));
}
