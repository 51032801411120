"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useShouldShowUpdateSubmitButton = useShouldShowUpdateSubmitButton;
var react_1 = require("react");
var hooks_1 = require("@shared/util/permissions/hooks");
var PermissionsContext_1 = require("@shared/util/permissions/PermissionsContext");
/**
 * Decides if the system should show the "Save" button on the update
 * card screen
 */
function useShouldShowUpdateSubmitButton(dirty, channelId) {
    var globalAbility = (0, react_1.useContext)(PermissionsContext_1.PermissionsContext);
    var ability = (0, hooks_1.getChannelAbility)(globalAbility, channelId);
    return Boolean(dirty) && ability.can('update', 'Card');
}
