"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Config = void 0;
// @ts-strict-ignore
var ms = __importStar(require("milliseconds"));
var react_native_1 = require("react-native");
var env_1 = require("@shared/util/env");
exports.Config = {
    VERSION_STRING: env_1.VERSION_STRING,
    GIPHY_API_KEY: 'bZwrkoQzkfAe4L81zQBvjXBA5t5wqYSw',
    GIPHY_API_URL: 'https://api.giphy.com/v1/gifs',
    APPSFLYER_API_KEY: 'DtMj4eg5G4CJRp2o9a6YNi',
    GOOGLE_MAPS_API_KEY: react_native_1.Platform.select({
        android: 'AIzaSyDK20ykK3LOoGWQkK6G0Qs18Fpp683cHR0',
        ios: 'AIzaSyBRPW2Op5E1Up7HyGq7VYum_3vOf9pxZhM',
        web: env_1.GOOGLE_MAPS_WEB_API_KEY,
    }),
    VAPID_PUBLIC_KEY: 'BKPrnRwirsJiU75G4HIw6vlui8sQlqmTta39B122qAksDVtQYK8lRwEB5vG7rlMzRv76qnaFPVVk3R1k0k6hvQE',
    GESTURE_EXIT_VELOCITY: 0.2,
    // Every time we fetch chat messages, we fetch them in batches
    // of 25. This is many enough to add some room for scrolling
    // upwards but not too many to give the backend excessive load.
    NUM_CHAT_MESSAGES_TO_FETCH: 25,
    CARD_PAGINATION_SIZE: 30,
    // How many characters before the search result match should
    // we show? 3 chars => "yo |search match| lorem ipsum" => "yo "
    // is included.
    SEARCH_RESULT_NUM_CHARS_BEFORE_MATCH_TO_SHOW: 40,
    // With this flag on, all MBView's are wrapped in a dashed border.
    // This is really helpful for debugging to see how different views
    // behave.
    DEBUG_LAYOUT: false,
    // With this flag on, all analytics events will be printed through
    // console.warn
    DEBUG_ANALYTICS: false,
    // If true, all sentry breadcrumbs will be console.warned
    DEBUG_SENTRY: false,
    // With this flag on, console.warn will be called after MBAnimation
    // has looped a few times.
    WARN_LOOPING_ANIMATIONS: false,
    // Toast visibility initial delay, allows screen transitions to occur before toast appears
    TOAST_DELAY_DURATION: ms.seconds(0.5),
    // Toast default visibility duration if none specified
    TOAST_DEFAULT_DURATION: ms.seconds(10),
    // If a user starts typing and then doesn't type for 5s
    // the user is considered to no longer be typing
    IS_TYPING_TIMEOUT_DURATION: ms.seconds(5),
    // Max interval to call API mutation to set the user as typing.
    // If the user is constantly typing, the mutation will be called
    // every 2 seconds.
    IS_TYPING_UPDATE_THROTTLE_DURATION: ms.seconds(2),
    // Only group messages that are sent within 1 minute from each other
    MESSAGE_GROUPING_TIME_SPAN: ms.minutes(1),
    // If true, all programmatic animations (eg MBAnimation) will be disabled.
    // This makes UI screenshot regression testing possible for animated components.
    DISABLE_ANIMATIONS: false,
    // Name of default font to use (needs to be consistent across iOS, Android and web)
    DEFAULT_FONT_FAMILY: react_native_1.Platform.select({
        // https://github.com/necolas/normalize.css/issues/665#issue-218860829
        web: '-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
        android: 'sans-serif',
        ios: 'System',
    }),
    // After the user has been prompted to sent a message by SMS to pending users
    // and answered (hidden the prompt or sent SMS), the prompt isn't visible
    // for another 24h.
    WAIT_DURATION_BEFORE_PROMPTING_TO_NOTIFY_PENDING_USERS: ms.hours(24),
    // Interval between times for the date time picker
    DEFAULT_WEB_DATE_PICKER_TIME_INTERVAL_MINUTES: 15,
    SENTRY_DSN: 'https://6da61a7434164592a74035ed0221cec6@sentry.io/1486111',
    FAQ_URL: 'https://help.coastapp.com',
    TERMS_OF_SERVICE_URL: 'https://coastapp.com/terms-of-service',
    PRIVACY_POLICY_URL: 'https://coastapp.com/privacy-policy',
    FORM_SUBMITTED_REDIRECT_URL: 'https://coastapp.com/success',
    CONTACT_EMAIL_URL: 'mailto:contact@coastapp.com',
    PRICING_LEARN_MORE_URL: 'https://help.coastapp.com/hc/en-us/articles/1500000014801-Message-card-and-storage-limits-on-the-free-version-of-Coast',
    // Note(fant): here we can add prod/dev/staging specific config as needed
    // On native, this is handled using react-native-config.
    IS_ENVIRONMENT_SWITCHER_AVAILABLE: env_1.EnvVars.IS_ENVIRONMENT_SWITCHER_AVAILABLE === 'true',
    // Overridable
    APP_URL: '',
    API_URL: '',
    AUTH_URL: '',
    BUILDER_APP_URL: '',
    WEBSOCKET_URL: '',
    IMGIX_URL: '',
    // API key for Segment analytics. Used by react-native-tsqd-segment
    SEGMENT_API_KEY: '',
    INTERCOM_APP_ID: '',
    ONESIGNAL_APP_ID: '',
    BRANCH_API_KEY: '',
    STRIPE_API_KEY: '',
    LAUNCH_DARKLY_CLIENT_KEY: '',
    LAUNCH_DARKLY_MOBILE_KEY: '',
    FACEBOOK_PIXEL_ID: null,
    APPLE_APP_ID: '1257883693',
    GOOGLE_PACKAGE_NAME: 'com.fomo.android.app',
    STORE_BUNDLED_VERSION: 'v0',
    /*deprecated*/ STRIPE_PLANS: {
        starter: {
            monthly: { price: 0, priceId: null },
            yearly: { price: 0, priceId: null },
        },
        plus: {
            monthly: { price: 0, priceId: null },
            yearly: { price: 0, priceId: null },
        },
    },
    PRICING_LIMIT_HISTORY_DAYS: 7,
    RECURRENCE_CARD_COUNT: 60,
    DELETE_ACCOUNT_URL: 'https://coastapp.com/account-deletion',
};
