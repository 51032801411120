"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateTagColor = exports.COLORS = exports.MAX_TAG_LENGTH = void 0;
var schemas_1 = require("@coast/schemas");
exports.MAX_TAG_LENGTH = 64;
exports.COLORS = [
    schemas_1.Color.tagPink,
    schemas_1.Color.tagYellow,
    schemas_1.Color.tagDewGreen,
    schemas_1.Color.tagCoastGreen,
    schemas_1.Color.tagCoolBlue,
    schemas_1.Color.tagPurple,
    schemas_1.Color.tagGray,
];
var generateTagColor = function (total) { return exports.COLORS[total % exports.COLORS.length]; };
exports.generateTagColor = generateTagColor;
