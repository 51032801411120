"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultTagSelection = void 0;
var components_1 = require("@shared/components");
var tag_1 = require("@shared/components/blocks/lib/tags/tag");
var hooks_1 = require("@shared/scenes/workflows/edit/hooks");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var DefaultTagSelection = function (_a) {
    var component = _a.component, overridePath = _a.overridePath;
    var _b = (0, hooks_1.useTagOptions)(component), options = _b.options, defaultValue = _b.defaultValue, onSetDefault = _b.onSetDefault, getOverrideValue = _b.getOverrideValue, onSetOverride = _b.onSetOverride;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var value = !(0, lodash_1.isNil)(overridePath) ? getOverrideValue(overridePath) : defaultValue;
    var setValue = !(0, lodash_1.isNil)(overridePath)
        ? function (val) { return onSetOverride(overridePath, val); }
        : onSetDefault;
    function newMultiselectDefaults(selectedValue) {
        if (value === null || value === void 0 ? void 0 : value.includes(selectedValue)) {
            return value.filter(function (item) { return item !== selectedValue; });
        }
        return [selectedValue].concat(value !== null && value !== void 0 ? value : []);
    }
    function newSingleSelectDefault(selectedValue) {
        if (value === null || value === void 0 ? void 0 : value.includes(selectedValue)) {
            return [];
        }
        return [selectedValue];
    }
    var onPress = function (newValue) {
        var newDefaults = component.multiselect
            ? newMultiselectDefaults(newValue)
            : newSingleSelectDefault(newValue);
        setValue(newDefaults);
    };
    return (react_1.default.createElement(components_1.MBView, { paddingBottom: 2 },
        react_1.default.createElement(components_1.MBView, { paddingHorizontal: 3, paddingVertical: 2 },
            react_1.default.createElement(components_1.MBTextOverlineBold, { color: colors.foregroundInactive }, (0, i18n_1.i18n)('workflowTemplate.responseOptions'))),
        options.map(function (option, index) {
            if (!(0, lodash_1.isEmpty)(option.label)) {
                return (react_1.default.createElement(react_1.default.Fragment, { key: index },
                    react_1.default.createElement(SelectTagRow, { option: option, isMultiselect: component.multiselect, isSelected: value === null || value === void 0 ? void 0 : value.includes(option.value), onPress: onPress })));
            }
            return null;
        })));
};
exports.DefaultTagSelection = DefaultTagSelection;
var SelectTagRow = function (_a) {
    var option = _a.option, isMultiselect = _a.isMultiselect, isSelected = _a.isSelected, onPress = _a.onPress;
    return (react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: function () { return onPress(option.value); } },
        react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, paddingVertical: 0.5, paddingHorizontal: 3 },
            react_1.default.createElement(components_1.MBView, { style: { alignContent: 'flex-start', flexWrap: 'wrap', flex: 4 }, flex: true, row: true, wrap: true },
                react_1.default.createElement(tag_1.Tag, { key: 0, color: option.backgroundColor },
                    react_1.default.createElement(components_1.MBView, { padding: 0.5 },
                        react_1.default.createElement(components_1.MBTextBody2, null, option.label)))),
            react_1.default.createElement(components_1.MBView, { style: {
                    alignItems: 'flex-end',
                    flex: 1,
                } },
                react_1.default.createElement(components_1.MBSelectableDot, { selected: isSelected, radio: !isMultiselect })))));
};
