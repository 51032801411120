"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBatchFieldOverrides = useBatchFieldOverrides;
var lodash_1 = require("lodash");
var react_1 = require("react");
function useBatchFieldOverrides(components) {
    return (0, react_1.useMemo)(function () {
        var componentsWithUpcomingFieldOverrides = components === null || components === void 0 ? void 0 : components.filter(isComponentWithBatchFieldOverrides).filter(function (component) { var _a; return !(0, lodash_1.isNil)((_a = component.upcomingRecurringEntityDefault) === null || _a === void 0 ? void 0 : _a.value); });
        var componentsWithFirstFieldOverrides = components === null || components === void 0 ? void 0 : components.filter(isComponentWithBatchFieldOverrides).filter(function (component) { var _a; return !(0, lodash_1.isNil)((_a = component.firstRecurringEntityDefault) === null || _a === void 0 ? void 0 : _a.value); });
        if ((0, lodash_1.isEmpty)(componentsWithUpcomingFieldOverrides) &&
            (0, lodash_1.isEmpty)(componentsWithFirstFieldOverrides)) {
            return undefined;
        }
        var overrides = {};
        componentsWithUpcomingFieldOverrides === null || componentsWithUpcomingFieldOverrides === void 0 ? void 0 : componentsWithUpcomingFieldOverrides.forEach(function (component) {
            var _a, _b;
            if ((0, lodash_1.isNil)(overrides.upcomingEntityFieldOverrides)) {
                overrides.upcomingEntityFieldOverrides = { fields: {} };
            }
            if (!(0, lodash_1.isNil)((_a = component.upcomingRecurringEntityDefault) === null || _a === void 0 ? void 0 : _a.value)) {
                overrides.upcomingEntityFieldOverrides.fields[component.id] =
                    (_b = component.upcomingRecurringEntityDefault) === null || _b === void 0 ? void 0 : _b.value;
            }
        });
        componentsWithFirstFieldOverrides === null || componentsWithFirstFieldOverrides === void 0 ? void 0 : componentsWithFirstFieldOverrides.forEach(function (component) {
            var _a, _b;
            if ((0, lodash_1.isNil)(overrides.firstEntityFieldOverrides)) {
                overrides.firstEntityFieldOverrides = { fields: {} };
            }
            if (!(0, lodash_1.isNil)((_a = component.firstRecurringEntityDefault) === null || _a === void 0 ? void 0 : _a.value)) {
                overrides.firstEntityFieldOverrides.fields[component.id] =
                    (_b = component.firstRecurringEntityDefault) === null || _b === void 0 ? void 0 : _b.value;
            }
        });
        return overrides;
    }, [components]);
}
function isComponentWithBatchFieldOverrides(component) {
    return component.type === 'TAG' || component.type === 'AUDIT_TAG';
}
