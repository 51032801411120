"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelatedEntityPicker = RelatedEntityPicker;
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var multiSelect_1 = require("@shared/components/multiSelect");
var contentWrapper_1 = require("@shared/scenes/auth/contentWrapper");
var titleBar_1 = require("@shared/components/bottomSheet/titleBar");
var searchEmpty_1 = require("@shared/components/blocks/lib/inputCards/searchEmpty");
var noCards_1 = require("@shared/components/blocks/lib/inputCards/noCards");
var i18n_1 = require("@shared/util/i18n");
var useRelatedEntitySearch_1 = require("./useRelatedEntitySearch");
function RelatedEntityPicker(_a) {
    var entities = _a.entities, containerRef = _a.containerRef, onChange = _a.onChange, onClose = _a.onClose, component = _a.component, fields = _a.fields;
    var _b = (0, useRelatedEntitySearch_1.useRelatedEntitySearch)(component, fields), onSearch = _b.onSearch, searchResults = _b.searchResults, loading = _b.loading, hasMore = _b.hasMore;
    var renderTitleBar = (0, react_1.useCallback)(function () {
        var _a;
        return (react_1.default.createElement(titleBar_1.MBBottomSheetTitleBar, { title: (_a = component.label) !== null && _a !== void 0 ? _a : '', rightIcon: "exit", onRightIconPress: onClose }));
    }, [onClose, component.label]);
    var match = (0, react_1.useCallback)(function (entity, query, exact) {
        var _a, _b;
        var name = (_b = (_a = entity.fields) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '';
        if (exact) {
            return name === query;
        }
        return true;
    }, []);
    var renderEmptyState = (0, react_1.useCallback)(function (query) {
        return (react_1.default.createElement(components_1.MBView, { paddingVertical: 3, paddingHorizontal: 2, center: true },
            query.length > 0 && react_1.default.createElement(searchEmpty_1.SearchEmpty, { keyword: query }),
            query.length === 0 && !loading && react_1.default.createElement(noCards_1.NoCards, null),
            query.length === 0 && loading && react_1.default.createElement(components_1.MBLoadingIndicator, null)));
    }, [loading]);
    var renderItem = (0, react_1.useCallback)(function (entity) { return react_1.default.createElement(EntityPickerItem, { entity: entity }); }, []);
    var isDesktop = (0, contentWrapper_1.useIsDesktop)();
    var fixedWidth = isDesktop ? (0, components_1.grid)(46) : undefined;
    return (react_1.default.createElement(components_1.MBBottomSheet, { containerRef: containerRef, renderTitleBar: renderTitleBar, layout: {
            offset: { x: (0, components_1.grid)(10), y: 0 },
            minWidth: fixedWidth,
            minHeight: (0, components_1.grid)(48),
        }, onClose: onClose },
        react_1.default.createElement(components_1.MBView, { style: { width: fixedWidth }, fill: true },
            react_1.default.createElement(multiSelect_1.MBMultiSelect, { onChange: onChange, keyExtractor: function (entity) { return entity.id; }, match: match, renderItem: renderItem, renderEmptyState: renderEmptyState, values: searchResults, selectedValues: entities, onSearch: onSearch, helpText: hasMore ? (0, i18n_1.i18n)('card.blocks.cardsInput.cardListLimitHelp') : undefined, displaySelected: true }))));
}
function EntityPickerItem(_a) {
    var _b, _c;
    var entity = _a.entity;
    return react_1.default.createElement(components_1.MBTextBody1, { style: { paddingLeft: (0, components_1.grid)(1) } }, (_c = (_b = entity.fields) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : '');
}
